import { useCookieConfiguration, useCookiePermissionSelection } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useReportError } from '/machinery/ReportError'
import { routeMap } from '/routeMap'
import { ButtonPrimary, ButtonLinkSecondary } from '/features/buildingBlocks/Button'
import { CookieToggles } from '/features/pageOnly/CookieToggles'

import styles from './CookiePermissions.css'

export function CookiePermissions({ initialConfiguration, layoutClassName = undefined }) {
  const clientConfig = useClientConfig()
  const availablePermissions = clientConfig.cookieConfiguration.availablePermissions
  const reportError = useReportError()

  const {
    storeSelection,
  } = useCookieConfiguration({
    availablePermissions,
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportError
  })

  const {
    selection,
  } = useCookiePermissionSelection({
    initialConfiguration
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.cookieTogglesWrapper}>
        <CookieToggles {... { initialConfiguration }} />
      </div>

      <div className={styles.buttonWrapper}>
        <ButtonPrimary onClick={handleStoreSelection}  dataX='click-to-save-selection'>
          Wijzigingen opslaan
        </ButtonPrimary>

        <ButtonLinkSecondary layoutClassName={styles.buttonLayout} dataX='link-to-home' href={routeMap.app.home({ language: 'nl' })}>Terug naar de curry</ButtonLinkSecondary>
      </div>
    </div>
  )

  function handleStoreSelection() {
    storeSelection({ permissions: selection })
  }
}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}
