import { useCookiePermissionSelection } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'

import styles from './CookieToggles.css'

const translations = {
  statistics: {
    title: 'Statistieken',
    content: `Deze cookies helpen ons te begrijpen hoe bezoekers
interactie hebben met onze website door anoniem informatie te verzamelen en te
rapporteren. Statistieken cookies kunnen maximaal 24 maanden bewaard worden.`

  },
  necessary: {
    title: 'Noodzakelijk',
    content: `Deze cookies zijn essentieel voor het functioneren van de
              website en kunnen niet worden uitgeschakeld in onze systemen. Deze cookies
              worden doorgaans verwijderd zodra u uw browser sluit.`
  },
  marketing: {
    title: 'Marketing',
    content: `Deze cookies worden gebruikt om bezoekers te volgen wanneer
ze verschillende websites bezoeken. Hun doel is om advertenties weer te geven die
zijn toegesneden op en relevant zijn voor de individuele gebruiker. Marketing cookies
worden maximaal 12 maanden bewaard.`
  },
  preferences: {
    title: 'Voorkeuren',
    content: `Deze cookies zorgen ervoor dat de website informatie kan
onthouden die de manier waarop de website zich gedraagt of eruitziet, zoals uw
voorkeurstaal of de regio waarin u zich bevindt, beïnvloedt. Voorkeurscookies worden
voor een periode van maximaal 12 maanden bewaard.`
  },
}

const classificationOrder = ['necessary', 'preferences', 'statistics', 'marketing']

function sortClassifications(classifications) {
  return classifications.sort((a, b) => {
    return classificationOrder.indexOf(a.classification) - classificationOrder.indexOf(b.classification)
  })
}

export function CookieToggles({ initialConfiguration, layoutClassName = undefined, small = false }) {
  const clientConfig = useClientConfig()
  const availablePermissions = clientConfig.cookieConfiguration.availablePermissions
  const classifications = useClassifications({ availablePermissions: clientConfig.cookieConfiguration.availablePermissions })
  const sortedClassifications = sortClassifications(classifications)

  const {
    selection,
    grantPermission,
    denyPermission,
  } = useCookiePermissionSelection({
    initialConfiguration
  })

  return (
    <div className={cx(styles.component, small && styles.isSmall, layoutClassName)}>
      {sortedClassifications.map(({ name, classification, required }) => (
        <CookiePermission
          id={classification}
          key={classification}
          title={translations[classification].title}
          content={translations[classification].content}
          onToggleChange={({ value }) => handlePermissionChange({ classification, value })}
          active={selection[name] === 'granted'}
          disabled={required}
          layoutClassName={styles.cookiePermissionLayout}
          {... { small }}
        />
      ))}
    </div>
  )

  function handlePermissionChange({ classification, value }) {
    availablePermissions
      .filter(x => x.classification === classification)
      .forEach(x => handleValueChange({ id: x.name, value }))
  }

  function handleValueChange({ id, value }) {
    value === true
      ? grantPermission(id)
      : denyPermission(id)
  }
}

function CookiePermission({
  id,
  title,
  content,
  active,
  onToggleChange,
  disabled = undefined,
  small = false,
  layoutClassName = undefined
}) {
  return (
    <div className={cx(styles.componentCookiePermission, disabled && styles.isDisabled, small && styles.isSmall, layoutClassName)}>
      <h4 className={cx(styles.heading, disabled && styles.isDisabled, small && styles.isSmall)}>{title}</h4>

      {!small && <span  className={cx(styles.content, small && styles.isSmall)}>
        {content}
      </span>}

      <button
        type="button"
        onClick={handleClick}
        className={cx(
          styles.toggleButton,
          active && styles.isActive,
          disabled && styles.isDisabled,
          small && styles.isSmall
        )}
        {... { id, disabled }}
      >
        <span className={cx(styles.option, active && styles.isActive)}>Aan</span>
        <span className={cx(styles.option, !active && styles.isActive)}>Uit</span>
        <span className={cx( styles.slider, disabled && styles.isDisabled, active && styles.isActive)} />
      </button>
    </div>
  )


  function handleClick() {
    onToggleChange({ id, value: !active })
  }
}

function useClassifications({ availablePermissions }) {
  const [classifications, setClassifications] = React.useState([])

  React.useEffect(() => {
    const uniqueClassifications = Array
      .from(new Set(availablePermissions.map(x => x.classification)))
      .map(classification => availablePermissions.find(x => x.classification === classification))
    setClassifications(uniqueClassifications)
  }, [availablePermissions])

  return Object.values(classifications)
}
