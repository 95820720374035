import { ButtonTertiary, ButtonSecondary } from '/features/buildingBlocks/Button'
import { useCookieConfiguration, useCookiePermissionSelection } from '@kaliber/cookie-consent'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useReportError } from '/machinery/ReportError'
import { routeMap } from '/routeMap'
import { CookieToggles } from '/features/pageOnly/CookieToggles'
import { useGlobalPageState } from '@kaliber/use-global-page-state'


import styles from './CookieBar.css'

export function CookieBar({ initialConfiguration, layoutClassName = undefined }) {
  const clientConfig = useClientConfig()
  const reportError = useReportError()

  const {
    configuration,
    acceptAll,
    rejectAll,
    storeSelection,
  } = useCookieConfiguration({
    availablePermissions: clientConfig.cookieConfiguration.availablePermissions,
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    initialConfiguration,
    onChange: pushCookieConsent,
    onError: reportError
  })

  const {
    selection,
  } = useCookiePermissionSelection({
    initialConfiguration
  })

  const displayConsent = configuration?.show ?? false

  return displayConsent ? (
    <aside className={cx(styles.component, layoutClassName)}>
      <div className={styles.layout}>
        <p className={styles.intro}>
          Wij gebruiken cookies om uw ervaring op onze website te verbeteren, om het gebruik van
          onze website te analyseren, en om gerichte advertenties te tonen.
          <br /><br /> Door op 'Alles toestaan' te
          klikken, stemt u in met het gebruik van alle cookies zoals beschreven in onze
          cookieverklaring. Voor meer informatie over hoe lang cookies worden bewaard, verwijzen wij naar <a className={styles.link} href={routeMap.app.cookiePolicy({ language: 'nl' })} data-x='manage-cookies'>de cookie-instellingen</a>.
        </p>
        <div className={styles.cookieTogglesWrapper}>
          <CookieToggles small {... { initialConfiguration }} />
        </div>
        <div className={styles.actions}>
          <ButtonTertiary onClick={rejectAll} layoutClassName={styles.buttonLayout} dataX='reject-cookies'>Weigeren</ButtonTertiary>
          <ButtonTertiary onClick={handleStoreSelection} layoutClassName={styles.buttonLayout} dataX='click-to-save-selection'>Selectie toestaan</ButtonTertiary>
          <ButtonSecondary onClick={acceptAll} layoutClassName={styles.buttonLayout} dataX='accept-cookies'>Alles toestaan</ButtonSecondary>
        </div>
      </div>
    </aside>
  ) : <aside style={{ display: 'none' }} />

  function handleStoreSelection() {
    storeSelection({ permissions: selection })
  }

}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}



